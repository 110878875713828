import React from 'react';
import AccessGuard from "../../auth/guard";
import {ROLES} from '../../auth/guard/roles';
import {Link, withRouter} from 'react-router-dom';
import {withFullscreen} from '../fullscreen';

const Menu = ({location}) => {
    const path = location.pathname;
    const isPropertiesSectionActive = path === '/' || /^\/property\//.test(path);
    const isPropertiesAndBookingsActive = path === '/';
    const isBookingsManagementActive = /^\/property\/events/.test(path);
    const isGuestCheckListActive = /^\/property\/guest-check-list/.test(path);
    const isPropertiesManagementActive = /^\/property\/management/.test(path);
    const isBuildingManagementActive = /^\/property\/buildings/.test(path);
    //const isLandlordManagementActive = /^\/property\/landlords/.test(path);
    const isLandlordsV2ManagementActive = /^\/property\/landlords-v2/.test(path);
    const isAgentManagementActive = /^\/property\/agents/.test(path);
    const isRevenueSectionActive = /^\/revenue\//.test(path);
    const isRentalsUnitedSectionActive = /^\/rentals-united\//.test(path);
    const isRevenuePricingOverviewActive = path === '/revenue/prices/grid';
    const isRevenueManagePricesActive = path === '/revenue/prices/calendar';
    const isRevenueManageEventsActive = path === '/revenue/events';
    const isRevenueStatsWithBulkDiscountsActive = path === '/revenue/stats-with-bulk-discounts';
    const isRevenueUpdateHistoryActive = path === '/revenue/update-history';
    const isRevenuePricingSummaryActive = path === '/revenue/summary';
    const isRentalsUnitedLogViewerActive = path === '/rentals-united/logs';
    const isRentalsUnitedChannelSyncActive = path === '/rentals-united/channel-sync';
    const isRevenueLOSDiscountActive = path === '/revenue/los-discounts';
    const isAnalysisSectionActive = /^\/analysis\//.test(path);
    const isAirbnbAnalysisActive = path === '/analysis/airbnb';
    const isMaintenanceSectionActive = /^\/maintenance\//.test(path);
    const isMaintenanceDepartmentsActive = path === '/maintenance/case-categories';
    const isMaintenanceStaffActive = path === '/maintenance/staff';
    const isMaintenanceNotificationsActive = path === '/maintenance/notifications';
    const isMaintenanceRegistryActive = path === '/maintenance/registry';
    const isMaintenanceAssignmentsActive = path === '/maintenance/assignments';
    const isHousekeepingSectionActive = /^\/housekeeping\//.test(path);
    const isHousekeepingBuildingsConfigActive = path === '/housekeeping/buildings';
    const isHousekeepingEventsActive = path === '/housekeeping/events';
    const isHousekeepingTimelineActive = path === '/housekeeping/timeline';
    const isInvoicingSectionActive = /^\/invoicing/.test(path);
    const isContactsSectionActive = /^\/contacts/.test(path);
    const isManagementSectionActive = /^\/management/.test(path);
    const isSalesSectionActive = /^\/sales\//.test(path);
    const isSalesTargetActive = path === '/sales/target';
    const isSalesAnalysisActive = path === '/sales/analysis';
    const isSalesChannelRevenueActive = path === '/sales/channel-revenue';
    const isAccountingSectionActive = /^\/accounting/.test(path);
    const isAccountingBookkeepingActive = path === '/accounting/bookkeeping';
    const isAccountingBudgetsActive = path === '/accounting/departments-budgets';
    const isAccountingDepartmentExpensesActive = path === '/accounting/departments-expenses';
    const isAccountingDepartmentExpenseCategoriesActive = path === '/accounting/department-expense-categories';
    const isUstellaSectionActive = /^\/ustella/.test(path);
    const isUstellaBuildingsActive = path === '/ustella/buildings';
    const isUstellaLandlordsActive = path === '/ustella/landlords';
    const isUstellaPropertiesActive = path === '/ustella/properties';
    const isUstellaCompaniesActive = path === '/ustella/companies';
    const isUstellaCommercialLeaseActive = path === '/ustella/commercial-leases';
    const isUstellaTenantsActive = path === '/ustella/tenants';
    const isUstellaLongTermLeaseActive = path === '/ustella/long-term-leases';
    const isUstellaMaintenanceActive = path === '/ustella/maintenance';
    const isUstellaExpensesActive = path === '/ustella/expenses';
    const isUstellaViewingScheduleActive = path === '/ustella/viewing-schedule';
    const isSmartLocksSectionActive = /^\/smart-locks/.test(path);
    const isSmartLocksActive = path === '/smart-locks/locks';
    const isSmartHome365LocksActive = path === '/smart-locks/smarthome-365-locks';
    const isSmartLocksPasscodesActive = path === '/smart-locks/passcodes';
    const isSmartHome365PasscodesActive = path === '/smart-locks/smarthome-365-passcodes';
    const isTuyaIotLocksActive = path === '/smart-locks/tuya-iot-locks';
    const isTuyaIotPasscodesActive = path === '/smart-locks/tuya-iot-passcodes';
    const isKnowledgeBaseSectionActive = /^\/knowledge-base/.test(path);
    const isWebsiteUserManagementActive = path === '/property/users-management';
    const isCampaignManagementActive = path === '/property/campaigns';

    return (
        <aside className="left-sidebar">
            {/*  Sidebar scroll */} 
            <div className="scroll-sidebar">
                {/*  Sidebar navigation */} 
                <nav className="sidebar-nav">
                    <ul id="sidebarnav">
                        {/* properties */}
                        <AccessGuard allowAny={ROLES.PROPERTIES_MENU}>
                            <li className={isPropertiesSectionActive? 'active': ''} style={{marginLeft: '12px'}}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/'} aria-expanded="false">
                                    <i className="icon-home"/>
                                    <span className="hide-menu">Properties</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    <AccessGuard allowAny={['timeline-admin', 'timeline-editor', 'timeline-viewer', 'timeline-viewer-with-prices']}>
                                        <li className={isPropertiesAndBookingsActive? 'active': ''}>
                                            <Link className={isPropertiesAndBookingsActive? 'active': ''} to={'/'}>
                                                <i className="fa fa-calendar mr-2"/>
                                                Bookings timeline
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['bookings-list-admin', 'bookings-list-editor', 'bookings-list-viewer']}>
                                        <li className={isBookingsManagementActive? 'active': ''}>
                                            <Link className={isBookingsManagementActive? 'active': ''} to={'/property/events'}>
                                                <i className="fa fa-calendar-plus-o mr-2"/>
                                                Manage bookings
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['bookings-list-admin', 'bookings-list-editor', 'bookings-list-viewer']}>
                                        <li className={isGuestCheckListActive? 'active': ''}>
                                            <Link className={isGuestCheckListActive? 'active': ''} to={'/property/guest-check-list'}>
                                                <i className="fa fa-check-circle mr-2"/>
                                                Guest checklist
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['bookings-list-admin', 'bookings-list-editor', 'bookings-list-viewer']}>
                                        <li className={isWebsiteUserManagementActive? 'active' : ''}>
                                            <Link className={isWebsiteUserManagementActive? 'active' : ''} to={'/property/users-management'}>
                                                <i className='fa fa-user mr-2' />
                                                Manage users
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['properties-admin', 'properties-editor', 'properties-viewer']}>
                                        <li className={isPropertiesManagementActive? 'active': ''}>
                                            <Link className={isPropertiesManagementActive? 'active': ''} to={'/property/management'}>
                                                <i className="fa fa-suitcase mr-2"/>
                                                Manage properties
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['buildings-admin', 'buildings-editor', 'buildings-viewer']}>
                                        <li className={isBuildingManagementActive? 'active': ''}>
                                            <Link className={isBuildingManagementActive? 'active': ''} to={'/property/buildings'}>
                                                <i className="fa fa-building mr-2"/>
                                                Manage buildings
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    {/* Temporarily hidden to prevent confusion with Ustella > Landlords */}
                                    {/*<AccessGuard allowAny={['landlords-admin', 'landlords-editor', 'landlords-viewer']}>
                                        <li className={isLandlordManagementActive? 'active': ''}>
                                            <Link className={isLandlordManagementActive? 'active': ''} to={'/property/landlords'}>
                                                <i className="fa fa-user mr-2"/>
                                                Manage landlords
                                            </Link>
                                        </li>
                                    </AccessGuard>*/}
                                    <AccessGuard allowAny={['landlords-admin', 'landlords-editor', 'landlords-viewer']}>
                                        <li className={isLandlordsV2ManagementActive? 'active': ''}>
                                            <Link className={isLandlordsV2ManagementActive? 'active': ''} to={'/property/landlords-v2'}>
                                                <i className="fa fa-user mr-2"/>
                                                Manage landlords
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['agents-admin', 'agents-editor', 'agents-viewer']}>
                                        <li className={isAgentManagementActive? 'active': ''}>
                                            <Link className={isAgentManagementActive? 'active': ''} to={'/property/agents'}>
                                                <i className="fa fa-users mr-2"/>
                                                Manage agents
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['campaign-admin', 'campaign-editor', 'campaign-viewer']}>
                                        <li className={isCampaignManagementActive? 'active': ''}>
                                            <Link className={isCampaignManagementActive? 'active': ''} to={'/property/campaigns'}>
                                                <i className="fa fa-bullhorn mr-2"/>
                                                Manage campaigns
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                </ul>
                            </li>
                        </AccessGuard>
                        {/* revenue */}
                        <AccessGuard allowAny={['manager', 'developer', 'los-discount-editor']}>
                            <li className={isRevenueSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/revenue/prices/grid'} aria-expanded="false">
                                    <i className="fa fa-dollar"/>
                                    <span className="hide-menu">Revenue</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    <AccessGuard allowAny={[]}>
                                        <li className={isRevenuePricingOverviewActive? 'active': ''}>
                                            <Link className={isRevenuePricingOverviewActive? 'active': ''} to={'/revenue/prices/grid'}>
                                                <i className="fa fa-dollar mr-2"/>
                                                Pricing overview
                                            </Link>
                                        </li>
                                        <li className={isRevenueManagePricesActive? 'active': ''}>
                                            <Link className={isRevenueManagePricesActive? 'active': ''} to={'/revenue/prices/calendar'}>
                                                <i className="fa fa-suitcase mr-2"/>
                                                Manage prices
                                            </Link>
                                        </li>
                                        <li className={isRevenueManageEventsActive? 'active': ''}>
                                            <Link className={isRevenueManageEventsActive? 'active': ''} to={'/revenue/events'}>
                                                <i className="fa fa-calendar-check-o mr-2"/>
                                                Manage events
                                            </Link>
                                        </li>
                                        <li className={isRevenueStatsWithBulkDiscountsActive? 'active': ''}>
                                            <Link className={isRevenueStatsWithBulkDiscountsActive? 'active': ''} to={'/revenue/stats-with-bulk-discounts'}>
                                                <i className="icon-energy mr-2"/>
                                                Stats & bulk prices
                                            </Link>
                                        </li>
                                        <li className={isRevenueUpdateHistoryActive? 'active': ''}>
                                            <Link className={isRevenueUpdateHistoryActive? 'active': ''} to={'/revenue/update-history'}>
                                                <i className="icon-layers mr-2"/>
                                                History of updates
                                            </Link>
                                        </li>
                                        <li className={isRevenuePricingSummaryActive? 'active': ''}>
                                            <Link className={isRevenuePricingSummaryActive? 'active': ''} to={'/revenue/summary'}>
                                                <i className="icon-paper-clip mr-2"/>
                                                Pricing summary
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['los-discount-editor']}>
                                        <li className={isRevenueLOSDiscountActive? 'active': ''}>
                                            <Link className={isRevenueLOSDiscountActive? 'active': ''} to={'/revenue/los-discounts'}>
                                                <i className="fa fa-calendar-o mr-2"/>
                                                LOS Discounts
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                </ul>
                            </li>
                        </AccessGuard>
                        {/* sales */}
                        <AccessGuard allowAny={ROLES.SALES_MENU}>
                            <li className={isSalesSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/sales/target'} aria-expanded="false">
                                    <i className="fa fa-pie-chart"/>
                                    <span className="hide-menu">Sales</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    <li className={isSalesTargetActive? 'active': ''}>
                                        <Link className={isSalesTargetActive? 'active': ''} to={'/sales/target'}>
                                            <i className="icon-target mr-2"/>
                                            Targets by month
                                        </Link>
                                    </li>
                                    <li className={isSalesAnalysisActive? 'active': ''}>
                                        <Link className={isSalesAnalysisActive? 'active': ''} to={'/sales/analysis'}>
                                            <i className="icon-energy mr-2"/>
                                            Targets analysis
                                        </Link>
                                    </li>
                                    <li className={isSalesChannelRevenueActive? 'active': ''}>
                                        <Link className={isSalesChannelRevenueActive? 'active': ''} to={'/sales/channel-revenue'}>
                                            <i className="icon-chart mr-2"/>
                                            Revenue per channel
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </AccessGuard>
                        {/* RentalsUnited */}
                        <AccessGuard allowAny={[]}>
                            <li className={isRentalsUnitedSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/rentals-united/logs'} aria-expanded="false">
                                    <i className="fa fa-file"/>
                                    <span className="hide-menu">Rentals United</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    <li className={isRentalsUnitedLogViewerActive? 'active': ''}>
                                        <Link className={isRentalsUnitedLogViewerActive? 'active': ''} to={'/rentals-united/logs'}>
                                            <i className="fa fa-file mr-2"/>
                                            Logs viewer
                                        </Link>
                                    </li>
                                    <li className={isRentalsUnitedChannelSyncActive? 'active': ''}>
                                        <Link className={isRentalsUnitedChannelSyncActive? 'active': ''} to={'/rentals-united/channel-sync'}>
                                            <i className="fa fa-clock-o mr-2"/>
                                            Channel sync
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </AccessGuard>
                        {/* Analysis */}
                        <AccessGuard allowAny={[]}>
                            <li className={isAnalysisSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/analysis/airbnb'} aria-expanded="false">
                                    <i className="icon-energy"/>
                                    <span className="hide-menu">Analysis</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    <li className={isAirbnbAnalysisActive? 'active': ''}>
                                        <Link className={isAirbnbAnalysisActive? 'active': ''} to={'/analysis/airbnb'}>
                                            <i className="icon-energy mr-2"/>
                                            Airbnb
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </AccessGuard>
                        {/* Maintenance */}
                        <AccessGuard allowAny={ROLES.MAINTENANCE_MENU}>
                            <li className={isMaintenanceSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/maintenance/registry'} aria-expanded="false">
                                    <i className="icon-briefcase"/>
                                    <span className="hide-menu">Maintenance</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    <AccessGuard allowAny={['maintenance-admin','maintenance-editor','maintenance-viewer']}>
                                        <li className={isMaintenanceRegistryActive? 'active': ''}>
                                            <Link className={isMaintenanceRegistryActive? 'active': ''} to={'/maintenance/registry'}>
                                                <i className="fa fa-check-circle-o mr-2"/>
                                                Registry
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['maintenance-admin']}>
                                        <li className={isMaintenanceAssignmentsActive? 'active': ''}>
                                            <Link className={isMaintenanceAssignmentsActive? 'active': ''} to={'/maintenance/assignments'}>
                                                <i className="fa fa-object-group mr-2"/>
                                                Assignments
                                            </Link>
                                        </li>
                                        <li className={isMaintenanceDepartmentsActive? 'active': ''}>
                                            <Link className={isMaintenanceDepartmentsActive? 'active': ''} to={'/maintenance/case-categories'}>
                                                <i className="icon-people mr-2"/>
                                                Case Categories
                                            </Link>
                                        </li>
                                        <li className={isMaintenanceStaffActive? 'active': ''}>
                                            <Link className={isMaintenanceStaffActive? 'active': ''} to={'/maintenance/staff'}>
                                                <i className="icon-user mr-2"/>
                                                Staff
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['maintenance-notifications-editor']}>
                                        <li className={isMaintenanceNotificationsActive ? 'active': ''}>
                                            <Link className={isMaintenanceNotificationsActive ? 'active': ''} to={'/maintenance/notifications'}>
                                                <i className="icon-bell mr-2"/>
                                                Notifications
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                </ul>
                            </li>
                        </AccessGuard>
                        {/* Cleanings */}
                        <AccessGuard allowAny={ROLES.HOUSEKEEPING_MENU}>
                            <li className={isHousekeepingSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/housekeeping/buildings'} aria-expanded="false">
                                    <i className="fa fa-calendar"/>
                                    <span className="hide-menu">Housekeeping</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    <li className={isHousekeepingBuildingsConfigActive? 'active': ''}>
                                        <Link className={isHousekeepingBuildingsConfigActive? 'active': ''} to={'/housekeeping/buildings'}>
                                            <i className="fa fa-building mr-2"/>
                                            Configure buildings
                                        </Link>
                                    </li>
                                    <li className={isHousekeepingEventsActive? 'active': ''}>
                                        <Link className={isHousekeepingEventsActive? 'active': ''} to={'/housekeeping/events'}>
                                            <i className="fa fa-list mr-2"/>
                                            Manage housekeeping
                                        </Link>
                                    </li>
                                    <li className={isHousekeepingTimelineActive? 'active': ''}>
                                        <Link className={isHousekeepingTimelineActive? 'active': ''} to={'/housekeeping/timeline'}>
                                            <i className="fa fa-calendar mr-2"/>
                                            Housekeeping timeline
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </AccessGuard>
                        { /* Invoicing */}
                        <AccessGuard allowAny={ROLES.INVOICING_MENU}>
                            <li className={isInvoicingSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/invoicing'} aria-expanded="false">
                                    <i className="fa fa-money"/>
                                    <span className="hide-menu">Invoicing</span>
                                </Link>
                            </li>
                        </AccessGuard>
                        { /* Accounting */}
                        <AccessGuard allowAny={[...ROLES.ACCOUNTING_MENU, 'expense-manager']}>
                            <li className={isAccountingSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/accounting/bookkeeping'} aria-expanded="false">
                                    <i className="fa fa-book"/>
                                    <span className="hide-menu">Accounting</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    <AccessGuard allowAny={ROLES.ACCOUNTING_MENU}>
                                        <li className={isAccountingBookkeepingActive? 'active': ''}>
                                            <Link className={isAccountingBookkeepingActive? 'active': ''} to={'/accounting/bookkeeping'}>
                                                <i className="fa fa-book mr-2"/>
                                                Bookkeeping
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={[]}>
                                        <li className={isAccountingBudgetsActive? 'active': ''}>
                                            <Link className={isAccountingBudgetsActive? 'active': ''} to={'/accounting/departments-budgets'}>
                                                <i className="fa fa-money mr-2"/>
                                                Budgets
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <li className={isAccountingDepartmentExpensesActive? 'active': ''}>
                                        <Link className={isAccountingDepartmentExpensesActive? 'active': ''} to={'/accounting/departments-expenses'}>
                                            <i className="fa fa-dollar mr-3"/>
                                            Expenses
                                        </Link>
                                    </li>
                                    <li className={isAccountingDepartmentExpenseCategoriesActive? 'active': ''}>
                                        <Link className={isAccountingDepartmentExpenseCategoriesActive? 'active': ''} to={'/accounting/department-expense-categories'}>
                                            <i className="fa fa-address-card mr-2"/>
                                            Expense categories
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </AccessGuard>
                        { /* Contacts */}
                        <AccessGuard allowAny={ROLES.CONTACTS_MENU}>
                            <li className={isContactsSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/contacts'} aria-expanded="false">
                                    <i className="fa fa-address-book-o"/>
                                    <span className="hide-menu">Contacts</span>
                                </Link>
                            </li>
                        </AccessGuard>
                        {/* Ustella */}
                        <AccessGuard allowAny={ROLES.USTELLA_MENU}>
                            <li className={isUstellaSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/ustella/buildings'} aria-expanded="false">
                                    <i className="fa fa-building"/>
                                    <span className="hide-menu">Ustella</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    {/* buildings */}
                                    <AccessGuard allowAny={['ustella-buildings-admin', 'ustella-buildings-editor', 'ustella-buildings-viewer', 'ustella-all-admin', 'ustella-all-editor', 'ustella-all-viewer']}>
                                        <li className={isUstellaBuildingsActive? 'active': ''}>
                                            <Link className={isUstellaBuildingsActive? 'active': ''} to={'/ustella/buildings'}>
                                                <i className="fa fa-building-o mr-2"/>
                                                Buildings
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    {/* landlords */}
                                    <AccessGuard allowAny={['ustella-landlords-admin', 'ustella-landlords-editor', 'ustella-landlords-viewer', 'ustella-all-admin', 'ustella-all-editor', 'ustella-all-viewer']}>
                                        <li className={isUstellaLandlordsActive? 'active': ''}>
                                            <Link className={isUstellaLandlordsActive? 'active': ''} to={'/ustella/landlords'}>
                                                <i className="fa fa-user mr-2"/>
                                                Landlords
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    {/* properties */}
                                    <AccessGuard allowAny={['ustella-properties-admin', 'ustella-properties-editor', 'ustella-properties-viewer', 'ustella-all-admin', 'ustella-all-editor', 'ustella-all-viewer']}>
                                        <li className={isUstellaPropertiesActive? 'active': ''}>
                                            <Link className={isUstellaPropertiesActive? 'active': ''} to={'/ustella/properties'}>
                                                <i className="fa fa-home mr-2"/>
                                                Properties
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    {/* companies */}
                                    <AccessGuard allowAny={['ustella-company-admin', 'ustella-company-editor', 'ustella-company-viewer', 'ustella-all-admin', 'ustella-all-editor', 'ustella-all-viewer']}>
                                        <li className={isUstellaCompaniesActive? 'active': ''}>
                                            <Link className={isUstellaCompaniesActive? 'active': ''} to={'/ustella/companies'}>
                                                <i className="fa fa-suitcase mr-2"/>
                                                Companies
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    {/* commercial leases */}
                                    <AccessGuard allowAny={['ustella-commercial-lease-admin', 'ustella-commercial-lease-editor', 'ustella-commercial-lease-viewer', 'ustella-all-admin', 'ustella-all-editor', 'ustella-all-viewer']}>
                                        <li className={isUstellaCommercialLeaseActive? 'active': ''}>
                                            <Link className={isUstellaCommercialLeaseActive? 'active': ''} to={'/ustella/commercial-leases'}>
                                                <i className="fa fa-file-archive-o mr-2"/>
                                                Commercial leases
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    {/* tenants */}
                                    <AccessGuard allowAny={['ustella-tenants-admin', 'ustella-tenants-editor', 'ustella-tenants-viewer', 'ustella-all-admin', 'ustella-all-editor', 'ustella-all-viewer']}>
                                        <li className={isUstellaTenantsActive? 'active': ''}>
                                            <Link className={isUstellaTenantsActive? 'active': ''} to={'/ustella/tenants'}>
                                                <i className="icon-people mr-2"/>
                                                Tenants
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    {/* long-term leases */}
                                    <AccessGuard allowAny={['ustella-long-term-lease-admin', 'ustella-long-term-lease-editor', 'ustella-long-term-lease-viewer', 'ustella-all-admin', 'ustella-all-editor', 'ustella-all-viewer']}>
                                        <li className={isUstellaLongTermLeaseActive? 'active': ''}>
                                            <Link className={isUstellaLongTermLeaseActive? 'active': ''} to={'/ustella/long-term-leases'}>
                                                <i className="icon-drawer mr-2"/>
                                                Long-term leases
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    {/* maintenance */}
                                    <AccessGuard allowAny={['ustella-maintenance-admin', 'ustella-maintenance-editor', 'ustella-maintenance-viewer', 'ustella-all-admin', 'ustella-all-editor', 'ustella-all-viewer']}>
                                        <li className={isUstellaMaintenanceActive? 'active': ''}>
                                            <Link className={isUstellaMaintenanceActive? 'active': ''} to={'/ustella/maintenance'}>
                                                <i className="fa fa-check-circle-o mr-2"/>
                                                Maintenance
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    {/* expenses */}
                                    <AccessGuard allowAny={['ustella-expense-admin', 'ustella-expense-editor', 'ustella-expense-viewer', 'ustella-all-admin', 'ustella-all-editor', 'ustella-all-viewer']}>
                                        <li className={isUstellaExpensesActive? 'active': ''}>
                                            <Link className={isUstellaExpensesActive? 'active': ''} to={'/ustella/expenses'}>
                                                <i className="fa fa-dollar mr-2"/>
                                                Expenses
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    {/* viewing-schedule */}
                                    <AccessGuard allowAny={['ustella-viewing-schedule-admin', 'ustella-viewing-schedule-editor', 'ustella-viewing-schedule-viewer', 'ustella-all-admin', 'ustella-all-editor', 'ustella-all-viewer']}>
                                        <li className={isUstellaViewingScheduleActive? 'active': ''}>
                                            <Link className={isUstellaViewingScheduleActive? 'active': ''} to={'/ustella/viewing-schedule'}>
                                                <i className="fa fa-clock-o mr-2"/>
                                                Viewing Scheduler
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                </ul>
                            </li>
                        </AccessGuard>
                        {/* Smart-locks */}
                        <AccessGuard allowAny={ROLES.SMART_LOCKS_MENU}>
                            <li className={isSmartLocksSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/smart-locks/locks'} aria-expanded="false">
                                    <i className="icon-lock"/>
                                    <span className="hide-menu">Smart Locks</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    <AccessGuard allowAny={['smart-lock-admin', 'smart-lock-editor', 'smart-lock-viewer']}>
                                        <li className={isSmartLocksActive? 'active': ''}>
                                            <Link className={isSmartLocksActive? 'active': ''} to={'/smart-locks/locks'}>
                                                <i className="icon-lock mr-2"/>
                                                TTLock Locks
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['smart-lock-passcode-admin', 'smart-lock-passcode-editor', 'smart-lock-passcode-viewer']}>
                                        <li className={isSmartLocksPasscodesActive? 'active': ''}>
                                            <Link className={isSmartLocksPasscodesActive? 'active': ''} to={'/smart-locks/passcodes'}>
                                                <i className="icon-lock-open mr-2"/>
                                                TTLock Passcodes
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['smart-lock-admin', 'smart-lock-editor', 'smart-lock-viewer']}>
                                        <li className={isSmartHome365LocksActive? 'active': ''}>
                                            <Link className={isSmartHome365LocksActive? 'active': ''} to={'/smart-locks/smarthome-365-locks'}>
                                                <i className="icon-lock mr-2"/>
                                                SH-365 Locks
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['smart-lock-passcode-admin', 'smart-lock-passcode-editor', 'smart-lock-passcode-viewer']}>
                                        <li className={isSmartHome365PasscodesActive? 'active': ''}>
                                            <Link className={isSmartHome365PasscodesActive? 'active': ''} to={'/smart-locks/smarthome-365-passcodes'}>
                                                <i className="icon-lock-open mr-2"/>
                                                SH-365 Passcodes
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['smart-lock-admin', 'smart-lock-editor', 'smart-lock-viewer']}>
                                        <li className={isTuyaIotLocksActive? 'active': ''}>
                                            <Link className={isTuyaIotLocksActive? 'active': ''} to={'/smart-locks/tuya-iot-locks'}>
                                                <i className="icon-lock mr-2"/>
                                                Tuya Locks
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                    <AccessGuard allowAny={['smart-lock-passcode-admin', 'smart-lock-passcode-editor', 'smart-lock-passcode-viewer']}>
                                        <li className={isTuyaIotPasscodesActive? 'active': ''}>
                                            <Link className={isTuyaIotPasscodesActive? 'active': ''} to={'/smart-locks/tuya-iot-passcodes'}>
                                                <i className="icon-lock-open mr-2"/>
                                                Tuya Passcodes
                                            </Link>
                                        </li>
                                    </AccessGuard>
                                </ul>
                            </li>
                        </AccessGuard>
                        {/* Management */}
                        <AccessGuard allowAny={[]}>
                            <li className={isManagementSectionActive? 'active': ''}>
                                <Link className="has-arrow waves-effect waves-dark" to={'/management/accounts'} aria-expanded="false">
                                    <i className="icon-puzzle"/>
                                    <span className="hide-menu">Management</span>
                                </Link>
                                <ul aria-expanded="false" className="collapse in">
                                    <li className={isManagementSectionActive? 'active': ''}>
                                        <Link className={isManagementSectionActive? 'active': ''} to={'/management/accounts'}>
                                            <i className="icon-people mr-2"/>
                                            Accounts
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </AccessGuard>
                        {/* Knowledge Base */}
                        <li className={isKnowledgeBaseSectionActive? 'active': ''}>
                            <Link className="has-arrow waves-effect waves-dark" to={'/knowledge-base/landing'} aria-expanded="false">
                                <i className="fa fa-book"/>
                                <span className="hide-menu">Knowledge Base</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
                {/*  End Sidebar navigation  */} 
            </div>
            {/*  End Sidebar scroll */} 
        </aside>
    );
};

export default withFullscreen(withRouter(Menu));